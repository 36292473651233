.home {
    height: max(100vh,700px);
    /* min-height: 700px; */
}

@media (min-width: 1025px) {
    .home {
        height: calc(1150px + 500px);
    }

    .home[data="0"] {
        height: calc(1150px);
    }
}

::highlight(custom-highlight) {
  background-color: yellow;
  color: black;
}

@keyframes scale {
    0% { transform: scale(1); }
    50% { transform: scale(0.5); }
    100% { transform: scale(1); }
}


/* 隐藏垂直滚动条并将其宽度设置为0 */
::-webkit-scrollbar {
    width: 0;
}

/* 隐藏水平滚动条并将其高度设置为0 */
::-webkit-scrollbar {
    height: 0;
}

/* body{
    font-family: Söhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
} */